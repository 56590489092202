@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.wrapper {
	margin: 20px auto;
	width: 60%;
	max-width: 700px;
	z-index: 2;
	position: relative;
	display: flex;
	flex-direction: row;
	gap: 5px;

	@include tablet() {
		margin: 40px auto;
	}

	&.hidden {
		visibility: hidden;
	}

	&.backgroundwhite {
		.after::after {
			background-color: $white;
		}
	}

	&.smallSizing {
		width: 80%;

		@include tablet() {
			width: 50%;
		}

		@include tabletLarge() {
			width: 30%;
		}
	}

	&.fullSizing {
		width: 100%;
		max-width: 100%;
	}

	&.largeSizing {
		width: 80%;
	}

	&.fullSizing {
		width: 100%;
	}

	&.darkLightType {
		.before:before,
		.after:after,
		&.noText {
			background-color: $grey;
		}
	}

	&.lightType {
		.before:before,
		.after:after,
		&.noText {
			background-color: $light-grey;
		}
	}

	&.lighterType {
		.before:before,
		.after:after,
		&.noText {
			background-color: $off-white;
		}
	}

	&.dashedType {
		.before:before,
		.after:after,
		&.noText {
			background-color: transparent;
			border: 1px dashed $light-grey;
		}
	}

	&.noPad {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.noneSpacing {
	margin: 0 auto;
}

.smallSpacing {
	@include tablet() {
		margin: 20px auto;
	}
}

.xsmallSpacing {
	margin: 10px auto;
	@include tablet() {
		margin: 10px auto;
	}
}

.xxsmallSpacing {
	margin: 5px auto;
	@include tablet() {
		margin: 5px auto;
	}
}

.mediumSpacing {
	margin: 30px auto;
}

.largeSpacing {
	margin: 40px auto;

	@include tabletLarge() {
		margin: 40px auto 60px;
	}
}

.noText {
	height: 1px;
	background-color: $deep-green;
	gap: 0;
}

.text {
	position: relative;
	text-align: center;
	color: $deep-green;
	display: block;
	font-weight: $normal-base;
	font-size: 1.6rem;
	z-index: 2;
	flex-shrink: 1;
	flex-grow: 0;

	&.darkLight {
		color: $grey;
	}

	&.light {
		color: $light-grey;
	}

	&.lighter {
		color: $off-white;
	}
}

.before {
	position: relative;
	height: 1px;
	align-self: center;
	flex-shrink: 1;
	flex-grow: 1;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background-color: $deep-green;
		z-index: -1;
	}
}
.after {
	position: relative;
	height: 1px;
	align-self: center;
	flex-shrink: 1;
	flex-grow: 1;

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		background-color: $deep-green;
		z-index: -1;
	}
}
