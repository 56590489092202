@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

ul.wrapper {
	display: flex;
	color: $white;
	flex-direction: column;

	@include desktop() {
		flex-direction: row;
		grid-column: 2 / span 9;
		justify-self: flex-end;
	}

	li a {
		display: block;
		padding: 0;
	}

	.item {
		display: flex;
		flex-direction: row;
		gap: 5px;
		cursor: pointer;

		&:focus {
			& .image {
				transform: scale(1.05);
			}
		}

		.image {
			border-radius: 100%;
			border: 1px solid $white;
			width: 16px;
			height: 16px;
			position: relative;
			margin-right: 14px;
			margin-left: 4px;
			overflow: hidden;

			& img {
				height: 100% !important;
				width: auto !important;
				position: absolute;
				top: 50% !important;
				left: 50% !important;
				transform: translate(-50%, -50%);
			}
		}
	}
}
