@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.wrapper {
	background: $deep-green;
	color: $off-white;
	padding: 40px 0;

	&.wrapperAccount {
		margin-top: 50px;
		height: 0;
		padding: 0;
		overflow: hidden;

		@include desktop() {
			margin-top: 0;
			padding: 40px 0 60px;
			height: auto;
			display: block;
		}
	}

	h4 {
		color: $off-white;
	}

	p,
	a {
		color: $off-white;
		font-weight: $normal-base;
	}

	.textSmall {
		font-size: 1.4rem;
	}

	.socials {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
		margin-bottom: 20px;

		.label {
			flex-basis: 100%;
			margin-bottom: 0;
			font-weight: $bold-base;
			max-width: none;
		}

		& img,
		& svg {
			cursor: pointer;
		}
	}
}

.vipList {
	@include tablet() {
		grid-column: 6 / span 7;
		grid-row: 1 / span 4;
	}
}

.column {
	@include tablet() {
		grid-column: 1 / 6;
	}

	@include desktop() {
		grid-column: span 2;
	}

	&:first-child {
		@include desktop() {
			grid-column: span 5;
		}
	}

	&:nth-child(2) {
		@include desktop() {
			// ensures 6th column is skipped, email field uses 5 columns
			grid-column: 7 / 9;
		}
	}

	&.footerListOpen ul li {
		display: block;

		&.footerListHead {
			margin-bottom: 10px;

			@include desktop() {
				margin-bottom: 20px;
			}
		}
	}

	ul,
	li {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	li {
		display: none;

		@include desktop() {
			display: block;
		}
	}

	.footerListHead {
		display: block;

		h4 {
			display: inline;
		}

		@include desktop() {
			margin-bottom: 20px;
		}
	}

	.footerListHeadDrop {
		cursor: pointer;

		@include desktop() {
			cursor: default;
		}

		span {
			top: 7px;
			position: relative;
			margin-left: 10px;

			@include desktop() {
				display: none;
			}
		}
	}
}

.logoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	.logo {
		margin: 30px 0 20px;
		width: 150px;
	}

	.copyright {
		text-align: center;
		font-size: 1.4rem;
		margin-bottom: 0;
	}
}
