@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.zigzagDivider {
	bottom: -6px;
	position: absolute;
	width: 100%;
	z-index: 2;
}

.zigzag {
	margin: 0 0;
	background-color: transparent;
	position: relative;
	z-index: 0;
	height: 13px;

	&::before,
	&::after {
		content: "";
		display: block;
		position: absolute;
		left: 0;
		right: 0;
	}

	&::before {
		height: 15px;
		width: 100%;
		top: 0;
		z-index: 0;
		height: 5px;
		background-image: url("/images/zig-zag-i.svg");
		background-repeat: repeat;
		background-size: 14px 6px;
		border-bottom: 8px solid $off-white;

		@include tabletLarge() {
			height: 8px;
			background-size: 16px 8px;
		}
	}
}

.zigzagProgress {
	bottom: 1px;
	position: absolute;
	z-index: 1;
	background-image: url("/images/zig-zag-progress-i.svg");
	background-position-y: 0px;
	background-size: 14px 12px;
	height: 12px;
	background-repeat: repeat-x;
	transition: width 0.4s ease-in-out;

	@include tabletLarge() {
		bottom: 0px;
		height: 14px;
		background-size: 16px 13px;
	}
}
