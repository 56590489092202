@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.currentLang {
	display: flex;
	flex-direction: row;
	gap: 5px;
	cursor: pointer;

	@include desktop() {
		transform: translateY(3px);
	}

	.image {
		border-radius: 100%;
		border: 1px solid $white;
		width: 16px;
		height: 16px;
		margin-left: 4px;
		margin-right: 14px;
		position: relative;
		overflow: hidden;

		@include desktop() {
			margin-left: 0;
			margin-right: 8px;
		}
	}

	.locale {
		text-transform: uppercase;
		color: $white;
		font-size: 1.8rem;
	}

	.icon {
		color: $white;
		display: none;

		@include desktop() {
			display: block;
			margin-left: 0;
			transform: translateY(-2px);
		}
	}
}
