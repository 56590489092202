@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;
@use "sass:color";

a.button:hover,
a.button {
	text-decoration: none;
}

.button {
	padding: 0 15px;
	background-color: $yellow;
	height: 48px;
	color: $deep-green;
	font-weight: $normal-base;
	font-size: 1.8rem;
	border-radius: 5px;
	display: inline-flex;
	flex-direction: row;
	justify-content: center;
	line-height: 1.3;
	justify-self: center;
	width: 100%;
	border: 1px solid $yellow;

	&.center {
		margin-left: auto;
		margin-right: auto;
		display: block;
	}

	&.underline {
		text-decoration: underline;
	}

	&.large {
		height: 55px;
	}

	&.small {
		height: 28px;
		font-size: 1.6rem;
		font-weight: 500;
	}

	&.tiny {
		height: 24px;
		font-size: 1.4rem;
		font-weight: 500;
	}

	&.smaller {
		font-size: 1.6rem;
		font-weight: 500;
		height: 40px;
	}

	&.hasImage {
		@include desktop() {
			height: auto;
		}
	}

	&.relative {
		width: auto;
	}

	&.isWorking {
		overflow: hidden;
		background-image: url("/images/loading.gif");
		background-size: 20px 20px;
		background-position: center center;
		background-repeat: no-repeat;

		&.fancyWorking {
			background-image: none;
			background-color: $white;
			position: relative;
			&:before {
				content: var(--fancy-working-text, "");
				z-index: 1;
				position: absolute;
				height: 100%;
				top: 50%;
				transform: translateY(-25%);
			}
			&:after {
				position: absolute;
				content: "";
				top: 3px;
				background-color: $yellow;
				border-radius: 3px;
				left: 3px;
				height: calc(100% - 6px);
				width: calc(100% - 6px);
				animation-name: fancyLoading;
				animation-duration: 10s;
				animation-iteration-count: infinite;
			}
		}

		div {
			opacity: 0;
		}
	}

	&.darkWorkingIcon {
		&.isWorking {
			background-image: url("/images/loading--dark.gif");
		}
	}

	&:hover {
		background-color: $active-yellow;
		color: rgba($deep-green, 0.8);
		border-color: $active-yellow;
	}

	&:focus {
		box-shadow: 0px 0px 4px rgba(242, 169, 0, 0.25);
		outline: none;
	}

	&:disabled {
		background: $faded-yellow;
		border-color: $faded-yellow;
		color: rgba($deep-green, 0.5);
		cursor: default;
	}

	&Facebook {
		color: $white;
		background: #447dbf;
		border-color: #447dbf;

		&:hover,
		&:focus {
			background: color.adjust(#447dbf, $lightness: 5%);
			border-color: color.adjust(#447dbf, $lightness: 5%);
			color: $white;
		}
	}

	&Whatsapp {
		color: $white;
		background: #44bf6e;
		border-color: #44bf6e;

		&:hover,
		&:focus {
			background: color.adjust(#44bf6e, $lightness: 5%);
			border-color: color.adjust(#44bf6e, $lightness: 5%);
			color: $white;
		}
	}

	&Ghost,
	&GhostLight {
		background-color: transparent;
		border-color: transparent;
		border: none;
		box-shadow: none;
		padding: 0;

		// Use a new class, not :not due to overriding specificity.
		&.maintainPadding {
			padding: 0 15px;
		}

		&.active {
			color: $yellow;
			position: relative;

			&:hover {
				color: $yellow;
			}

			&::after {
				@include desktop() {
					position: absolute;
					bottom: 5px;
					left: 20px;
					right: 20px;
					height: 2px;
					background: $yellow;
					content: " ";
					border-color: $yellow;
				}
			}
		}

		&:hover {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}

		&:focus {
			box-shadow: none;
		}

		&:disabled {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			cursor: default;
		}
	}

	&GhostLight {
		color: $white !important;
	}

	&Clear {
		background: $white;
		border: 1px solid $deep-green;

		&.selected {
			background: $deep-green;
			color: $white;

			&:hover {
				border: 1px solid rgba($deep-green, 0.8);
				background-color: color.adjust($deep-green, $lightness: 5%);
				color: $white;
			}

			&:focus {
				box-shadow: none;
			}
		}

		&:focus {
			box-shadow: 0px 0px 4px rgba($deep-green, 0.25);
		}

		&:hover {
			border: 1px solid rgba($deep-green, 0.8);
			background-color: $white;
			color: rgba($deep-green, 0.8);
		}

		&:disabled {
			background: $white;
			border: 1px solid rgba($deep-green, 0.5);
			color: $medium-grey;
			cursor: default;
		}
	}

	&ClearLight {
		background-color: $white;
		border: 1px solid $yellow;

		&.isWorking {
			background-image: url("/images/loading--dark.gif");
		}

		&.selected {
			background: $deep-green;
			color: $white;

			&:hover {
				border: 1px solid rgba($yellow, 0.8);
				background-color: color.adjust($deep-green, $lightness: 5%);
				color: $deep-green;
			}

			&:focus {
				box-shadow: none;
			}
		}

		&:focus {
			box-shadow: 0px 0px 4px rgba($yellow, 0.25);
		}

		&:hover {
			border: 1px solid rgba($yellow, 0.8);
			background-color: $white;
			color: rgba($deep-green, 0.8);
		}

		&:disabled {
			background: $white;
			border: 1px solid rgba($yellow, 0.5);
			color: $medium-grey;
			cursor: default;
		}
	}

	&ClearAlternate {
		background: $deep-green;
		border: 1px solid $white;
		color: $white;

		&.selected {
			background: $white;
			color: $deep-green;

			&:hover {
				border: 1px solid rgba($white, 0.8);
				background-color: color.adjust($white, $lightness: 5%);
				color: $white;
			}

			&:focus {
				box-shadow: none;
			}
		}

		&:focus {
			box-shadow: none;
		}

		&:hover {
			border: 1px solid rgba($white, 0.8);
			background-color: $deep-green;
			color: rgba($white, 0.8);
		}

		&:disabled {
			background: $deep-green;
			border: 1px solid rgba($white, 0.5);
			color: $medium-grey;
			cursor: default;
		}
	}

	&ClearNegative {
		background: $white;
		border: 1px solid $red;
		color: $red;

		&:hover {
			background-color: $white;
			border: 1px solid color.adjust($red, $lightness: -10%);
			color: color.adjust($red, $lightness: -10%);
		}

		&:disabled {
			background-color: $white;
			border: 1px solid $light-grey;
		}
	}

	&Negative {
		background: $red;
		color: $white;
		border: 1px solid $red;

		&:hover {
			background-color: $active-red;
			border: 1px solid $active-red;
			color: $white;
		}
		&:focus {
			box-shadow: 0px 0px 4px rgba(242, 169, 0, 0.25);
		}

		&:disabled {
			background-color: $faded-red;
			border: 1px solid $faded-red;
			color: $off-white;

			&:hover {
				color: $off-white;
			}
		}
	}

	&Ghost {
		background-color: transparent;
		border-color: transparent;
		box-shadow: none;
		padding: 0;

		// Use a new class, not :not due to overriding specificity.
		&.maintainPadding {
			padding: 0 15px;
		}

		&:hover {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
		}

		&:disabled {
			background-color: transparent;
			border-color: transparent;
			box-shadow: none;
			cursor: default;
		}
	}
}

.imgContain {
	display: none;

	@include tabletLarge() {
		display: block;
		position: relative;
		width: 100px;
		height: 100px;
		margin-bottom: 10px;
	}
	@include desktop() {
		width: 128px;
		height: 128px;
	}
}

.icon {
	height: 100%;
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
}

.buttonTextWrapper {
	position: relative;
	text-align: center;
	width: 100%;
	margin: auto 0;
	text-indent: 0;
	display: flex;
	align-items: center;
	justify-content: center;

	&.hasImage {
		@include tabletLarge() {
			flex-direction: column;
		}
	}

	&.alignLeft {
		justify-content: flex-start;
	}

	&.hasNoIcons {
		padding: 0;
	}

	&.spaceBetween {
		justify-content: space-between;
	}

	.iconLeft {
		margin-right: 10px;
	}

	.iconRight {
		margin-left: 10px;
	}

	.iconLeftSmall {
		margin-right: 5px;
	}

	.iconRightSmall {
		margin-left: 5px;
	}

	.icon {
		top: -1px;
		position: relative;
		display: inline-block;
	}
}

@keyframes fancyLoading {
	from {
		width: 0%;
	}

	to {
		width: calc(100% - 6px);
	}
}
