@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.voucherModal {
	text-align: center;
	padding: 20px 20px 10px;
	@include tablet() {
		padding: 10px 40px 10px;
	}

	h4 {
		margin-bottom: 10px;
	}
}

.content {
	padding: 10px 0px 0px;
	font-weight: bold;
	text-align: center;

	.discount {
		color: $orange;
		font-size: 2rem;
		line-height: 3rem;
		margin-bottom: 30px;
		@include tablet() {
			font-size: 2.4rem;
		}
	}

	.secondDiscount {
		position: relative;
		margin-top: -5px;
		margin-bottom: 10px;
		@include tablet() {
			margin-top: 0;
		}
	}

	button {
		margin-bottom: 20px;
	}

	.smallText {
		font-size: 1.4rem;
		margin-top: 10px;
		padding: 0 10px;
		margin-bottom: 10px;
		@include tablet() {
			font-size: 1.6rem;
			margin-top: 0;
			margin-bottom: 20px;
		}
	}
}
