@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.cookieBanner {
	position: fixed;
	bottom: 10px;
	right: 10px;
	padding: 20px;
	width: calc(100% - 20px);
	background-color: $light-green;
	z-index: 100000000;
	text-align: center;
	color: $white;

	@include tablet() {
		width: 350px;
	}

	p {
		color: $white;
	}
}

.btnHolder {
	display: flex;
	flex-direction: horizontal;
	gap: 20px;
}

.cookieManage {
	padding-bottom: 0;
}

.extraInfo {
	text-align: left;
	margin-bottom: 20px;
}

.toggle {
	p {
		margin-top: 10px;
		font-size: 1.4rem;
		line-height: 1.6rem;
	}
}
