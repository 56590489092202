@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.mobileNavBar {
	position: fixed;
	bottom: 0;
	width: 100%;
	left: 0;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
	background-color: $white;
	border-top: 1px solid $off-white;
	z-index: 2;

	@include desktop() {
		display: none;
	}

	ul {
		list-style: none;
		padding: 0 10px;
		margin: 0;
		display: grid;
		grid-template-columns: repeat(5, [col-start] 1fr);

		li {
			list-style: none;
			padding: 0;
			margin: 0;
			height: 100%;

			&.isActive {
				color: $light-green;

				a,
				a:hover,
				a:active,
				a:visited {
					color: $light-green;
				}
			}

			a {
				height: 100%;
				width: 100%;
				padding: 10px 0px;
				text-align: center;
				display: block;
				font-size: 14px;

				&:hover,
				a:active {
					text-decoration: none;
				}
			}
		}
	}
}
