@use "@/utils/mixins" as *;
@use "@/utils/variables" as *;

.quantity {
	background-color: transparent;
	display: inline-block;
	text-align: center;
	background-size: 24px auto;
	width: 40px;
	height: 40px;
	background-position: center center;
	background-repeat: no-repeat;
	color: $deep-green;

	&.more {
		margin-left: 10px;
	}

	&.less {
		margin-right: 10px;
	}

	&.inactive {
		opacity: 0.2;
	}
}

.icon {
	transform: translateY(5px);
}
